var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-404" },
    [
      _c("v-container", { staticClass: "d-flex justify-center" }, [
        _c("h1", [
          _vm._v(
            " " + _vm._s(_vm._f("upperFirst")(_vm.$t("page not found"))) + " "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }